<template>
    <v-container>
        <v-row class="text-center" justify="center" style="min-height: 80vh;">
            <v-col cols="12" md="10" lg="9" align-self="center" class="text-center" v-if="loading">
                <v-progress-circular indeterminate color="primary" size="70"></v-progress-circular>
            </v-col>
            <v-col cols="12" md="10" lg="9" align-self="center" class="text-center" v-if="!loading && valid">
                <font-awesome-icon icon="fa-duotone fa-circle-check" size="9x" />
                <h1 class="mt-4">Account wurde erfolgreich aktiviert!</h1>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { getFunctions, httpsCallable } from "firebase/functions";

const functions = getFunctions();
const userActivate = httpsCallable(functions, 'user-activate');

export default {
    name: 'Account-Aktivierung',
    data() {
        return {
            valid: false,
            id: '',
            verify: '',
            loading: true
        }
    },
    mounted() {
        if (this.getQueryVariable('id') && this.getQueryVariable('code')) {
            this.id = this.getQueryVariable('id')
            this.verify = this.getQueryVariable('code')
            userActivate({ id: this.id, verify: this.verify }).then((result) => {
                if(result.data.code == 200){
                    this.loading = false
                    this.valid = true
                }
            })
        }
    },
    methods: {
        getQueryVariable(variable) {
            var query = window.location.search.substring(1)
            var vars = query.split('&')
            for (var i = 0; i < vars.length; i++) {
                var pair = vars[i].split('=')
                if (pair[0] == variable) {
                return pair[1]
                }
            }
            return false
        },
    }
}
</script>